<template>
  <div class="content fs-6 d-flex flex-column flex-column-fluid">
    <div class="toolbar">
      <div class="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
        <div
          class="d-flex flex-column align-items-start justify-content-center flex-wrap me-2"
        >
          <h1 class="text-dark fw-bolder my-1 fs-2">Mairies</h1>
        </div>
        <div class="d-flex align-items-center flex-nowrap text-nowrap py-1">
          <span v-if="cities" class="badge badge-primary">{{
            cities.count
          }}</span>
        </div>
      </div>
    </div>
    <div class="post fs-6 d-flex flex-column-fluid">
      <div class="container-fluid">
        <div class="card">
          <div class="card-header border-0 pt-6">
            <div class="card-title">
              <div class="d-flex align-items-center position-relative my-1">
                <span class="svg-icon svg-icon-1 position-absolute ms-6">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x="17.0365"
                      y="15.1223"
                      width="8.15546"
                      height="2"
                      rx="1"
                      transform="rotate(45 17.0365 15.1223)"
                      fill="black"
                    />
                    <path
                      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <form @submit.prevent="getAllCities()">
                  <input
                    v-model="filter.search"
                    type="text"
                    class="form-control form-control-solid w-250px ps-14 me-3"
                    placeholder="Chercher une mairie"
                  />
                </form>
                <button
                  v-if="filter.search"
                  type="button"
                  class="btn btn-light-danger"
                  @click.prevent="resetSearch()"
                >
                  Supprimer la recherche
                </button>
              </div>
            </div>
            <div class="card-toolbar">
              <div class="d-flex justify-content-end">
                <div class="me-3 d-flex align-items-center">
                  <input
                    v-model="scrollValue"
                    type="range"
                    min="0"
                    :max="maxScroll"
                    class="form-range"
                    @input="scrollTable"
                  />
                </div>
                <div class="dropdown me-3">
                  <button
                    id="dropdownMenuButton1"
                    type="button"
                    class="btn btn-light-primary"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="false"
                    aria-expanded="false"
                  >
                    <span class="svg-icon svg-icon-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.3"
                          x="2"
                          y="2"
                          width="20"
                          height="20"
                          rx="5"
                          fill="black"
                        />
                        <path
                          d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    Masquer des colonnes
                  </button>
                  <div
                    class="dropdown-menu menu menu-sub menu-sub-dropdown w-md-300px"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <div class="px-7 py-5">
                      <div class="fs-5 text-dark fw-bolder">Colonnes</div>
                    </div>
                    <div class="separator border-gray-200"></div>
                    <form class="px-7 py-5">
                      <div class="mb-3">
                        <input
                          id="codgeo_id"
                          type="checkbox"
                          class="form-check-input"
                          checked
                          @change="handleCheckboxChange('codgeo')"
                        />
                        <label class="form-label fw-bold ps-2" for="codgeo_id">
                          Codgeo</label
                        >
                      </div>
                      <div class="mb-3">
                        <input
                          id="department_id"
                          type="checkbox"
                          class="form-check-input"
                          checked
                          @change="handleCheckboxChange('department')"
                        />
                        <label
                          class="form-label fw-bold ps-2"
                          for="department_id"
                        >
                          Département</label
                        >
                      </div>
                      <div class="mb-3">
                        <input
                          id="libcom_2014_id"
                          type="checkbox"
                          class="form-check-input"
                          checked
                          @change="handleCheckboxChange('libcom_2014')"
                        />
                        <label
                          class="form-label fw-bold ps-2"
                          for="libcom_2014_id"
                        >
                          Libcom 2014</label
                        >
                      </div>
                      <div class="mb-3">
                        <input
                          id="zonage_abc_id"
                          type="checkbox"
                          class="form-check-input"
                          checked
                          @change="handleCheckboxChange('zonage_abc')"
                        />
                        <label
                          class="form-label fw-bold ps-2"
                          for="zonage_abc_id"
                        >
                          Zonage abc</label
                        >
                      </div>
                      <div class="mb-3">
                        <input
                          id="plafond_loyer_id"
                          type="checkbox"
                          class="form-check-input"
                          checked
                          @change="handleCheckboxChange('plafond_loyer')"
                        />
                        <label
                          class="form-label fw-bold ps-2"
                          for="plafond_loyer_id"
                        >
                          Plafond loyer</label
                        >
                      </div>
                      <div class="mb-3">
                        <input
                          id="nombre_de_logements_ville_id"
                          type="checkbox"
                          class="form-check-input"
                          checked
                          @change="
                            handleCheckboxChange('nombre_de_logements_ville')
                          "
                        />
                        <label
                          class="form-label fw-bold ps-2"
                          for="nombre_de_logements_ville_id"
                        >
                          Nb de logements ville</label
                        >
                      </div>
                      <div class="mb-3">
                        <input
                          id="prix_immo_ancien_dvf_moyen_id"
                          type="checkbox"
                          class="form-check-input"
                          checked
                          @change="
                            handleCheckboxChange('prix_immo_ancien_dvf_moyen')
                          "
                        />
                        <label
                          class="form-label fw-bold ps-2"
                          for="prix_immo_ancien_dvf_moyen_id"
                        >
                          Prix immo ancien dvf moyen</label
                        >
                      </div>
                      <div class="mb-3">
                        <input
                          id="prix_immo_ancien_3e_quartile_id"
                          type="checkbox"
                          class="form-check-input"
                          checked
                          @change="
                            handleCheckboxChange('prix_immo_ancien_3e_quartile')
                          "
                        />
                        <label
                          class="form-label fw-bold ps-2"
                          for="prix_immo_ancien_3e_quartile_id"
                        >
                          Prix immo ancien 3e quartile</label
                        >
                      </div>
                      <div class="mb-3">
                        <input
                          id="nb_de_transactions_id"
                          type="checkbox"
                          class="form-check-input"
                          checked
                          @change="handleCheckboxChange('nb_de_transactions')"
                        />
                        <label
                          class="form-label fw-bold ps-2"
                          for="nb_de_transactions_id"
                        >
                          Nb transactions</label
                        >
                      </div>
                      <div class="mb-3">
                        <input
                          id="prix_de_reference_hlm_ht_id"
                          type="checkbox"
                          class="form-check-input"
                          checked
                          @change="
                            handleCheckboxChange('prix_de_reference_hlm_ht')
                          "
                        />
                        <label
                          class="form-label fw-bold ps-2"
                          for="prix_de_reference_hlm_ht_id"
                        >
                          Prix de référence HLM HT</label
                        >
                      </div>
                      <div class="mb-3">
                        <input
                          id="decote_prix_ancien_id"
                          type="checkbox"
                          class="form-check-input"
                          checked
                          @change="handleCheckboxChange('decote_prix_ancien')"
                        />
                        <label
                          class="form-label fw-bold ps-2"
                          for="decote_prix_ancien_id"
                        >
                          Décote prix ancien</label
                        >
                      </div>
                      <div class="mb-3">
                        <input
                          id="premiere_estimation_valeur_social_ht_id"
                          type="checkbox"
                          class="form-check-input"
                          checked
                          @change="
                            handleCheckboxChange(
                              'premiere_estimation_valeur_social_ht'
                            )
                          "
                        />
                        <label
                          class="form-label fw-bold ps-2"
                          for="premiere_estimation_valeur_social_ht_id"
                        >
                          Première estimation valeur social HT</label
                        >
                      </div>
                      <div class="mb-3">
                        <input
                          id="seuil_social_logements_id"
                          type="checkbox"
                          class="form-check-input"
                          checked
                          @change="
                            handleCheckboxChange('seuil_social_logements')
                          "
                        />
                        <label
                          class="form-label fw-bold ps-2"
                          for="seuil_social_logements_id"
                        >
                          Seuil social logements</label
                        >
                      </div>
                      <div class="mb-3">
                        <input
                          id="seuil_social_m2_id"
                          type="checkbox"
                          class="form-check-input"
                          checked
                          @change="handleCheckboxChange('seuil_social_m2')"
                        />
                        <label
                          class="form-label fw-bold ps-2"
                          for="seuil_social_m2_id"
                        >
                          Seuil social m2</label
                        >
                      </div>
                      <div class="mb-3">
                        <input
                          id="taux_lls_commune_id"
                          type="checkbox"
                          class="form-check-input"
                          checked
                          @change="handleCheckboxChange('taux_lls_commune')"
                        />
                        <label
                          class="form-label fw-bold ps-2"
                          for="taux_lls_commune_id"
                        >
                          Taux LLS commune</label
                        >
                      </div>
                      <div class="mb-3">
                        <input
                          id="mayor_name_id"
                          type="checkbox"
                          class="form-check-input"
                          checked
                          @change="handleCheckboxChange('mayor_name')"
                        />
                        <label
                          class="form-label fw-bold ps-2"
                          for="mayor_name_id"
                        >
                          Nom du maire</label
                        >
                      </div>
                      <div class="mb-3">
                        <input
                          id="mayor_age_id"
                          type="checkbox"
                          class="form-check-input"
                          checked
                          @change="handleCheckboxChange('mayor_age')"
                        />
                        <label
                          class="form-label fw-bold ps-2"
                          for="mayor_age_id"
                        >
                          Age du maire</label
                        >
                      </div>
                      <div class="mb-3">
                        <input
                          id="mayor_relation_quality_id"
                          type="checkbox"
                          class="form-check-input"
                          checked
                          @change="
                            handleCheckboxChange('mayor_relation_quality__in')
                          "
                        />
                        <label
                          class="form-label fw-bold ps-2"
                          for="mayor_relation_quality_id"
                        >
                          Qualité de relation du maire</label
                        >
                      </div>
                      <div class="mb-3">
                        <input
                          id="political_orientation_id"
                          type="checkbox"
                          class="form-check-input"
                          checked
                          @change="
                            handleCheckboxChange('political_orientation')
                          "
                        />
                        <label
                          class="form-label fw-bold ps-2"
                          for="political_orientation_id"
                        >
                          Parti politique</label
                        >
                      </div>
                      <div class="mb-3">
                        <input
                          id="election_date_id"
                          type="checkbox"
                          class="form-check-input"
                          checked
                          @change="handleCheckboxChange('election_date')"
                        />
                        <label
                          class="form-label fw-bold ps-2"
                          for="election_date_id"
                        >
                          Date d'élection</label
                        >
                      </div>
                      <div class="mb-3">
                        <input
                          id="is_1loyermoinscher_customer_id"
                          type="checkbox"
                          class="form-check-input"
                          checked
                          @change="
                            handleCheckboxChange('is_1loyermoinscher_customer')
                          "
                        />
                        <label
                          class="form-label fw-bold ps-2"
                          for="is_1loyermoinscher_customer_id"
                        >
                          Client 1loyermoinscher</label
                        >
                      </div>
                      <div class="mb-3">
                        <input
                          id="is_preemption_customer_id"
                          type="checkbox"
                          class="form-check-input"
                          checked
                          @change="
                            handleCheckboxChange('is_preemption_customer')
                          "
                        />
                        <label
                          class="form-label fw-bold ps-2"
                          for="is_preemption_customer_id"
                        >
                          Client préemption</label
                        >
                      </div>
                      <div class="mb-3">
                        <input
                          id="has_patrimoine_sur_la_commune_id"
                          type="checkbox"
                          class="form-check-input"
                          checked
                          @change="
                            handleCheckboxChange(
                              'has_patrimoine_sur_la_commune'
                            )
                          "
                        />
                        <label
                          class="form-label fw-bold ps-2"
                          for="has_patrimoine_sur_la_commune_id"
                        >
                          Patrimoine sur la commune</label
                        >
                      </div>
                      <div class="mb-3">
                        <input
                          id="already_implemented_id"
                          type="checkbox"
                          class="form-check-input"
                          checked
                          @change="handleCheckboxChange('already_implemented')"
                        />
                        <label
                          class="form-label fw-bold ps-2"
                          for="already_implemented_id"
                        >
                          Déjà implanté</label
                        >
                      </div>
                      <div class="mb-3">
                        <input
                          id="mayor_position_id"
                          type="checkbox"
                          class="form-check-input"
                          checked
                          @change="handleCheckboxChange('mayor_position')"
                        />
                        <label
                          class="form-label fw-bold ps-2"
                          for="mayor_position_id"
                        >
                          Profession du maire</label
                        >
                      </div>
                    </form>
                  </div>
                </div>
                <CitiesFilters />
              </div>
            </div>
          </div>

          <!-- Loader -->
          <Loader :data="cities" />

          <div v-if="cities">
            <div v-if="cities.count > 0">
              <div class="card-body p-0">
                <div
                  ref="tableResponsiveCities"
                  class="table-responsive"
                  @scroll="onTableScroll"
                >
                  <table
                    v-if="cities.results"
                    class="table table-flush table-responsive align-middle table-row-bordered table-row-solid gy-4"
                  >
                    <div class="h-700px overflow-scroll">
                      <thead
                        style="
                          position: sticky;
                          top: 0;
                          z-index: 1;
                          background-color: white;
                        "
                      >
                        <tr
                          class="text-start fw-bolder fs-6 text-uppercase gs-0"
                        >
                          <th
                            v-if="columns.includes('codgeo')"
                            class="min-w-100px ps-9"
                          >
                            Codgeo
                          </th>
                          <th
                            v-if="columns.includes('department')"
                            class="min-w-100px ps-9"
                          >
                            Department
                          </th>
                          <th
                            v-if="columns.includes('libcom_2014')"
                            class="min-w-100px ps-9"
                          >
                            Libcom 2014
                          </th>
                          <th
                            v-if="columns.includes('zonage_abc')"
                            class="min-w-100px ps-9"
                          >
                            Zonage ABC
                          </th>
                          <th
                            v-if="columns.includes('plafond_loyer')"
                            class="min-w-100px ps-9"
                          >
                            Plafond Loyer
                          </th>
                          <th
                            v-if="columns.includes('nombre_de_logements_ville')"
                            class="min-w-125px ps-9"
                          >
                            Nombre de Logements Ville
                          </th>
                          <th
                            v-if="
                              columns.includes('prix_immo_ancien_dvf_moyen')
                            "
                            class="min-w-125px ps-9"
                          >
                            Prix Immo Ancien DVF Moyen
                          </th>
                          <th
                            v-if="
                              columns.includes('prix_immo_ancien_3e_quartile')
                            "
                            class="min-w-125px ps-9"
                          >
                            Prix Immo Ancien 3e Quartile
                          </th>
                          <th
                            v-if="columns.includes('nb_de_transactions')"
                            class="min-w-100px ps-9"
                          >
                            Nb de Transactions
                          </th>
                          <th
                            v-if="columns.includes('prix_de_reference_hlm_ht')"
                            class="min-w-125px ps-9"
                          >
                            Prix de Référence HLM HT
                          </th>
                          <th
                            v-if="columns.includes('decote_prix_ancien')"
                            class="min-w-100px ps-9"
                          >
                            Décote Prix Ancien
                          </th>
                          <th
                            v-if="
                              columns.includes(
                                'premiere_estimation_valeur_social_ht'
                              )
                            "
                            class="min-w-125px ps-9"
                          >
                            Première Estimation Valeur Social HT
                          </th>
                          <th
                            v-if="columns.includes('seuil_social_logements')"
                            class="min-w-100px ps-9"
                          >
                            Seuil Social Logements
                          </th>
                          <th
                            v-if="columns.includes('seuil_social_m2')"
                            class="min-w-100px ps-9"
                          >
                            Seuil Social m2
                          </th>
                          <th
                            v-if="columns.includes('taux_lls_commune')"
                            class="min-w-100px ps-9"
                          >
                            Taux LLS Commune
                          </th>
                          <th
                            v-if="columns.includes('mayor_name')"
                            class="min-w-100px ps-9"
                          >
                            Mayor Name
                          </th>
                          <th
                            v-if="columns.includes('mayor_age')"
                            class="min-w-100px ps-9"
                          >
                            Age du maire
                          </th>
                          <th
                            v-if="columns.includes('mayor_relation_quality')"
                            class="min-w-125px ps-9"
                          >
                            Qualité de relation du maire
                          </th>
                          <th
                            v-if="columns.includes('political_orientation')"
                            class="min-w-100px ps-9"
                          >
                            Parti politique
                          </th>
                          <th
                            v-if="columns.includes('election_date')"
                            class="min-w-100px ps-9"
                          >
                            Date d'élection
                          </th>
                          <th
                            v-if="
                              columns.includes('is_1loyermoinscher_customer')
                            "
                            class="min-w-100px ps-9"
                          >
                            Client 1LoyerMoinsCher
                          </th>
                          <th
                            v-if="columns.includes('is_preemption_customer')"
                            class="min-w-100px ps-9"
                          >
                            Client préemption
                          </th>
                          <th
                            v-if="
                              columns.includes('has_patrimoine_sur_la_commune')
                            "
                            class="min-w-125px ps-9"
                          >
                            Patrimoine sur la Commune
                          </th>
                          <th
                            v-if="columns.includes('already_implemented')"
                            class="min-w-100px ps-9"
                          >
                            Déjà implanté
                          </th>
                          <th
                            v-if="columns.includes('mayor_position')"
                            class="min-w-100px ps-9"
                          >
                            Profession du maire
                          </th>
                        </tr>
                      </thead>
                      <tbody class="fw-bold text-gray-700">
                        <tr
                          v-for="city in cities.results"
                          :key="city.id"
                          style="cursor: pointer"
                        >
                          <template v-for="(value, key) in city">
                            <td
                              v-if="columns.includes(key)"
                              :key="key"
                              class="ps-9"
                            >
                              <router-link
                                :to="'/cities/' + city.id"
                                class="text-black"
                              >
                                <span v-if="value && key === 'election_date'">{{
                                  getMomentDate(value)
                                }}</span>
                                <span
                                  v-else-if="
                                    value &&
                                    (key === 'already_implemented' ||
                                      key === 'has_patrimoine_sur_la_commune' ||
                                      key === 'is_preemption_customer' ||
                                      key === 'is_1loyermoinscher_customer')
                                  "
                                  >{{ value === true ? "Oui" : "Non" }}</span
                                >
                                <span
                                  v-else-if="
                                    value && key === 'mayor_relation_quality'
                                  "
                                  class="ms-2 badge bg-light-warning text-dark"
                                  >{{ value }}
                                  <i class="bi bi-star-fill text-warning h6"></i
                                ></span>
                                <span v-else-if="value">{{ value }}</span>
                                <span v-else-if="!value" class="text-black-50"
                                  >Non renseigné</span
                                >
                              </router-link>
                            </td>
                          </template>
                        </tr>
                      </tbody>
                    </div>
                  </table>
                </div>
              </div>
              <div v-if="cities" class="card-footer">
                <div
                  class="row justify-content-center justify-content-sm-between align-items-sm-center"
                >
                  <TableFooter
                    v-if="cities"
                    :all-data="cities"
                    :default-size="30"
                    :page="filter.page"
                    :has-query="true"
                    @selectedSize="getSizeSelected"
                    @selectedPage="getPageSelected"
                    @nextPage="showNextCities(cities.next)"
                    @previousPage="showPreviousCities(cities.previous)"
                    @change="getAllCities"
                  />
                </div>
              </div>
            </div>
            <div v-else class="card-body ms-1">
              <div class="alert alert-secondary">
                <div class="d-flex flex-column">
                  <span class="text-dark">Aucune mairie enregistrée</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loader from "@/components/Loader";
import TableFooter from "@/components/TableFooter";
import CitiesFilters from "@/components/cities/CitiesFilters";
import store from "@/store";
import moment from "moment";

export default {
  name: "Cities",
  components: {
    Loader,
    TableFooter,
    CitiesFilters,
  },
  data() {
    return {
      filter: {
        order: "",
        search: "",
        size: "",
        defaultSize: 30,
        page: "",
      },
      columns: [
        "already_implemented",
        "codgeo",
        "decote_prix_ancien",
        "department",
        "election_date",
        "has_patrimoine_sur_la_commune",
        "is_1loyermoinscher_customer",
        "is_preemption_customer",
        "libcom_2014",
        "mayor_age",
        "mayor_name",
        "mayor_position",
        "mayor_relation_quality",
        "nb_de_transactions",
        "nombre_de_logements_ville",
        "plafond_loyer",
        "political_orientation",
        "premiere_estimation_valeur_social_ht",
        "prix_de_reference_hlm_ht",
        "prix_immo_ancien_3e_quartile",
        "prix_immo_ancien_dvf_moyen",
        "seuil_social_logements",
        "seuil_social_m2",
        "taux_lls_commune",
        "zonage_abc",
      ],
      scrollValue: 0,
      maxScroll: 100,
    };
  },
  computed: {
    ...mapGetters(["cities"]),
  },
  methods: {
    ...mapActions(["getCities"]),
    ...mapActions(["nextPaginate"]),
    ...mapActions(["previousPaginate"]),

    // Get cities
    getAllCities() {
      this.getCities(this.filter);
      this.citiesData = this.cities;
    },
    resetSearch() {
      this.filter.search = "";
      this.getAllCities();
    },

    // Ordering
    ascendingCodgeo() {
      this.filter.order = "codgeo";
      this.getAllCities();
    },
    descendingCodgeo() {
      this.filter.order = "-codgeo";
      this.getAllCities();
    },

    // Paginations
    async getPageSelected(res) {
      this.filter.page = res.currentPage;
    },
    async getSizeSelected(res) {
      this.filter.size = res.sizeSelected;
    },
    async showNextCities(url) {
      this.citiesData = await this.nextPaginate(url);
      store.commit("cities", {
        ...this.citiesData,
      });
    },
    async showPreviousCities(url) {
      this.citiesData = await this.previousPaginate(url);
      store.commit("cities", {
        ...this.citiesData,
      });
    },
    handleCheckboxChange(column) {
      const index = this.columns.indexOf(column);
      if (index !== -1) {
        this.columns.splice(index, 1);
      } else {
        this.columns.push(column);
      }
    },
    // Plugins
    getMomentDate(value) {
      return moment(value).locale("FR").format("DD/MM/YYYY");
    },
    scrollTable() {
      const tableContainer = this.$refs.tableResponsiveCities;
      if (tableContainer) {
        const scrollPercentage = this.scrollValue / this.maxScroll;
        const maxScrollAmount =
          tableContainer.scrollWidth - tableContainer.clientWidth;
        const scrollAmount = maxScrollAmount * scrollPercentage;
        tableContainer.scrollLeft = scrollAmount;
      }
    },
    onTableScroll() {
      const tableContainer = this.$refs.tableResponsiveCities;
      if (tableContainer) {
        const maxScrollAmount =
          tableContainer.scrollWidth - tableContainer.clientWidth;
        if (maxScrollAmount > 0) {
          const scrollPercentage = tableContainer.scrollLeft / maxScrollAmount;
          this.scrollValue = scrollPercentage * this.maxScroll;
        }
      }
    },
  },
  // eslint-disable-next-line vue/order-in-components
  mounted() {
    const query = { ...this.$route.query };
    if (query.page && query.size) {
      this.filter.page = query.page;
      this.filter.size = query.size;
    }
    this.getAllCities();
  },
};
</script>
