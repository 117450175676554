<template>
  <div class="dropdown">
    <button
      id="dropdownMenuButton1"
      type="button"
      class="btn btn-light-primary"
      data-bs-toggle="dropdown"
      data-bs-auto-close="false"
      aria-expanded="false"
    >
      <span class="svg-icon svg-icon-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
            fill="black"
          />
        </svg>
      </span>
      Filtrer
    </button>
    <div
      class="dropdown-menu menu menu-sub menu-sub-dropdown w-250px w-md-600px"
      aria-labelledby="dropdownMenuButton1"
    >
      <div class="d-flex px-7 py-5">
        <div class="fs-5 text-dark fw-bolder">Options</div>
      </div>
      <div class="separator border-gray-200"></div>
      <form class="px-7 py-5" @submit.prevent="getAllCities()">
        <div class="mb-10 d-flex row">
          <div class="mb-3 col-6">
            <label class="form-label fw-bold">Département :</label>
            <v-select
              v-model="depsSelected"
              multiple
              :options="departmentsArray"
              class="form-control"
            />
          </div>
          <div class="mb-3 col-6">
            <label class="form-label fw-bold">Région :</label>
            <v-select
              v-model="regionsSelected"
              multiple
              class="form-control"
              :options="regions"
            />
          </div>
          <div class="row mb-3 col-12">
            <label class="form-label fw-bold">Décote prix LLS :</label>
            <div class="col-6">
              <input
                v-model="filter.decote_prix_ancien__gte"
                class="form-control"
                type="input"
                placeholder="Min"
              />
            </div>
            <div class="col-6">
              <input
                v-model="filter.decote_prix_ancien__lte"
                class="form-control"
                type="input"
                placeholder="Max"
              />
            </div>
          </div>
          <div class="row mb-3 col-12">
            <label class="form-label fw-bold">Taux LLS :</label>
            <div class="col-6">
              <input
                v-model="filter.taux_lls_commune__gte"
                class="form-control"
                type="input"
                placeholder="Min"
              />
            </div>
            <div class="col-6">
              <input
                v-model="filter.taux_lls_commune__lte"
                class="form-control"
                type="input"
                placeholder="Max"
              />
            </div>
          </div>
          <div class="mb-3 d-flex row">
            <div class="mb-3 col-6">
              <label class="form-label fw-bold"
                >Parti politique du maire :</label
              >
              <select
                v-model="filter.political_orientation"
                class="form-select form-control"
              >
                <option value="Extrême droite">Extrême droite</option>
                <option value="Droite">Droite</option>
                <option value="Centre">Centre</option>
                <option value="Gauche">Gauche</option>
                <option value="Extrême gauche">Extrême gauche</option>
              </select>
            </div>
            <div class="mb-3 col-6">
              <label class="form-label fw-bold">Client 1LoyerMoinsCher :</label>
              <select
                v-model="filter.is_1loyermoinscher_customer"
                class="form-select form-control"
              >
                <option :value="true">Oui</option>
                <option :value="false">Non</option>
              </select>
            </div>
          </div>
          <div class="mb-3 d-flex row">
            <div class="col-6">
              <label class="form-label fw-bold">Client préemption :</label>
              <select
                v-model="filter.is_preemption_customer"
                class="form-select form-control"
              >
                <option :value="true">Oui</option>
                <option :value="false">Non</option>
              </select>
            </div>
            <div class="col-6">
              <label class="form-label fw-bold"
                >Qualité de relation du maire :</label
              >
              <v-select
                v-model="ratingsSelected"
                multiple
                class="form-control"
                :options="ratingsArray"
              />
            </div>
          </div>
          <div class="mb-3 d-flex row">
            <div class="mb-3 col-6">
              <label class="form-label fw-bold">Déjà implanté :</label>
              <select
                v-model="filter.already_implemented"
                class="form-select form-control"
              >
                <option :value="true">Oui</option>
                <option :value="false">Non</option>
              </select>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-end">
          <button
            class="btn btn-light-danger me-3"
            @click.prevent="resetFilters()"
          >
            Vider
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            data-kt-menu-dismiss="true"
          >
            Appliquer
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import store from "@/store";

export default {
  name: "CitiesFilters",
  data() {
    return {
      depsSelected: "",
      regionsSelected: "",
      ratingsSelected: "",
      departmentsArray: [],
      regions: [
        {
          label: "Auvergne-Rhône-Alpes",
          value: "1,3,7,15,26,38,42,43,63,69,73,74",
        },
        { label: "Bourgogne-Franche-Comté", value: "21,25,39,58,70,71,89,90" },
        { label: "Bretagne", value: "22,29,35,56" },
        { label: "Centre-Val de Loire", value: "18,28,36,37,41,45" },
        { label: "Corse", value: "2" },
        { label: "Grand Est", value: "8,10,51,52,54,55,57,67,6888" },
        { label: "Hauts-de-France", value: "2,59,60,62,80" },
        { label: "Île-de-France", value: "75,77,78,91,92,93,94,95" },
        { label: "Normandie", value: "14,27,50,61,76" },
        {
          label: "Nouvelle-Aquitaine",
          value: "16,17,19,23,24,33,40,47,64,79,86,87",
        },
        { label: "Occitanie", value: "9,11,12,30,31,32,34,46,48,65,66,81,82" },
        { label: "Pays de la Loire", value: "44,49,53,72,85" },
        { label: "Provence-Alpes-Côte d'Azur", value: "4,5,6,13,83,84" },
      ],
      ratingsArray: [
        { label: "1 ⭐️", value: 1 },
        { label: "2 ⭐️", value: 2 },
        { label: "3 ⭐️", value: 3 },
        { label: "4 ⭐️", value: 4 },
        { label: "5 ⭐️", value: 5 },
      ],
      filter: {
        order: "",
        search: "",
        size: "",
        defaultSize: 30,
        page: "",
        department: "",
        decote_prix_ancien__gte: "",
        decote_prix_ancien__lte: "",
        taux_lls_commune__gte: "",
        taux_lls_commune__lte: "",
        political_orientation: "",
        is_1loyermoinscher_customer: "",
        is_preemption_customer: "",
        mayor_relation_quality__in: "",
        already_implemented: "",
      },
    };
  },
  computed: {
    ...mapGetters(["citiesFilter"]),
    ...mapGetters(["cities"]),
  },
  mounted() {
    this.getDepartments();
  },
  methods: {
    ...mapActions(["getCities"]),
    async getAllCities() {
      this.filter.department = "";
      store.commit("cities", null);
      if (
        Array.isArray(this.ratingsSelected) &&
        this.ratingsSelected.length > 0
      ) {
        const values = this.ratingsSelected.map((element) => element.value);
        this.filter.mayor_relation_quality__in = values.join(",");
      }
      if (Array.isArray(this.depsSelected) && this.depsSelected.length > 0) {
        const labels = this.depsSelected.map((element) => element);
        this.filter.department = labels.join(",");
      }
      if (
        Array.isArray(this.regionsSelected) &&
        this.regionsSelected.length > 0
      ) {
        const values = this.regionsSelected.map((element) => element.value);
        if (this.filter.department === "") {
          this.filter.department = values.join(",");
        } else {
          this.filter.department += "," + values.join(",");
        }
      }
      await this.getCities(this.filter);
    },
    getDepartments() {
      for (let i = 1; i <= 95; i++) {
        this.departmentsArray.push(i.toString());
      }
    },
    resetFilters() {
      this.filter = {
        order: "",
        search: "",
        size: "",
        defaultSize: 30,
        page: "",
        department: "",
        decote_prix_ancien__gte: "",
        decote_prix_ancien__lte: "",
        taux_lls_commune__gte: "",
        taux_lls_commune__lte: "",
        political_orientation: "",
        is_1loyermoinscher_customer: "",
        is_preemption_customer: "",
        mayor_relation_quality__in: "",
        already_implemented: "",
      };
      this.regionsSelected = "";
      this.depsSelected = "";
      this.getCities(this.filter);
    },
  },
};
</script>
